export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILED = "AUTH_FAILED";
export const UPDATE_BAG = "UPDATE_BAG";
export const RESET_BAG = "RESET_BAG";
export const UPDATE_ADDRESSES = "UPDATE_ADDRESSES";
export const LOADING_TRUE = "LOADING_TRUE";
export const LOADING_FALSE = "LOADING_FALSE";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_GROUP = "GET_USER_GROUP";
export const INITIALIZE_CHECKOUT = "INITIALIZE_CHECKOUT";
export const UPDATE_CHECKOUT = "UPDATE_CHECKOUT";
export const SYNC_TRUE = "SYNC_TRUE";
export const SYNC_FALSE = "SYNC_FALSE";
export const SYNC_FAILED = "SYNC_FAILED";
export const SYNC_RESET = "SYNC_RESET";
