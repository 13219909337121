exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bag-js": () => import("./../../../src/pages/bag.js" /* webpackChunkName: "component---src-pages-bag-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-envios-e-entregas-js": () => import("./../../../src/pages/envios-e-entregas.js" /* webpackChunkName: "component---src-pages-envios-e-entregas-js" */),
  "component---src-pages-guia-de-tamanhos-js": () => import("./../../../src/pages/guia-de-tamanhos.js" /* webpackChunkName: "component---src-pages-guia-de-tamanhos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loja-index-js": () => import("./../../../src/pages/loja/index.js" /* webpackChunkName: "component---src-pages-loja-index-js" */),
  "component---src-pages-pesquisa-js": () => import("./../../../src/pages/pesquisa.js" /* webpackChunkName: "component---src-pages-pesquisa-js" */),
  "component---src-pages-politicias-de-privacidade-js": () => import("./../../../src/pages/politicias-de-privacidade.js" /* webpackChunkName: "component---src-pages-politicias-de-privacidade-js" */),
  "component---src-pages-promocoes-e-campanhas-js": () => import("./../../../src/pages/promocoes-e-campanhas.js" /* webpackChunkName: "component---src-pages-promocoes-e-campanhas-js" */),
  "component---src-pages-siga-sua-encomenda-js": () => import("./../../../src/pages/siga-sua-encomenda.js" /* webpackChunkName: "component---src-pages-siga-sua-encomenda-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */),
  "component---src-pages-trocas-e-devolucoes-js": () => import("./../../../src/pages/trocas-e-devolucoes.js" /* webpackChunkName: "component---src-pages-trocas-e-devolucoes-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-list-js": () => import("./../../../src/templates/product-list.js" /* webpackChunkName: "component---src-templates-product-list-js" */),
  "component---src-templates-product-list-outlet-js": () => import("./../../../src/templates/product-list-outlet.js" /* webpackChunkName: "component---src-templates-product-list-outlet-js" */),
  "component---src-templates-product-list-swimwear-js": () => import("./../../../src/templates/product-list-swimwear.js" /* webpackChunkName: "component---src-templates-product-list-swimwear-js" */)
}

