import React, { createContext, useReducer } from "react";

import AuthReducer from "../reducers/auth";

export const DEFAULT_STATE = {
  token: null,
  isExpired: true,
  isLoggedIn: false,
  isActive: true, // force
  isLoading: false,
  syncing: false,
  errorCount: 0,
  uuid: null,
  name: null,
  email: null,
  addresses: [],
  attributes: [],
  groups: [],
  bag: {
    id: "",
    items: [],
    subTotal: 0,
    shipping: 0,
    total: 0,
    currency: "EUR",
    email: null,
    nif: null,
    checkoutId: null,
  },
  checkout: {
    lineItems: [],
  },
};

export const AuthContext = createContext(DEFAULT_STATE);

export default function AuthProvider({ children }) {
  return (
    <AuthContext.Provider value={useReducer(AuthReducer, DEFAULT_STATE)}>
      {children}
    </AuthContext.Provider>
  );
}

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
);
