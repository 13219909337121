import * as actionsTypes from "../actions/types";

export default function authReducer(state, action) {
  switch (action.type) {
    case actionsTypes.AUTH_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        token: action.payload.token,
        isActive: action.payload.isActive,
        isExpired: action.payload.isExpired,
        isLoggedIn: true,
        isLoading: false,
        syncing: false,
        errorCount: 0,
        bag: {
          ...state.bag,
          email: action.payload.email,
        },
      };
    case actionsTypes.AUTH_FAILED:
      return {
        ...state,
        isExpired: true,
        uuid: null,
        name: null,
        email: null,
        token: null,
        isActive: false,
        isLoggedIn: false,
        isLoading: false,
        syncing: false,
        errorCount: 0,
        bag: {
          id: "",
          items: [],
          subTotal: 0,
          shipping: 0,
          total: 0,
          currency: "EUR",
          email: null,
          nif: null,
          checkoutId: null,
        },
        addresses: [],
        attributes: [],
        groups: [],
      };
    case actionsTypes.UPDATE_BAG:
      return {
        ...state,
        bag: {
          ...state.bag,
          id: action.bag.id,
          items: action.bag.items,
          subTotal: action.bag.subTotal,
          shipping: action.bag.shipping,
          total: action.bag.total,
          currency: action.bag.currency,
        },
      };
    case actionsTypes.RESET_BAG:
      return {
        ...state,
        syncing: false,
        errorCount: 0,
        bag: {
          ...state.bag,
          id: "",
          items: [],
          subTotal: 0,
          shipping: 0,
          total: 0,
          currency: "EUR",
          nif: null,
          checkoutId: null,
        },
      };
    case actionsTypes.UPDATE_ADDRESSES:
      return {
        ...state,
        addresses: action.addresses,
      };
    case actionsTypes.GET_USER_INFO:
      return {
        ...state,
        uuid: action.uuid,
        name: action.name,
        attributes: action.attributes,
      };
    case actionsTypes.LOADING_TRUE:
      return {
        ...state,
        isLoading: true,
      };
    case actionsTypes.LOADING_FALSE:
      return {
        ...state,
        isLoading: false,
      };
    case actionsTypes.GET_USER_GROUP:
      return {
        ...state,
        groups: action.groups,
      };
    case actionsTypes.INITIALIZE_CHECKOUT:
      return {
        ...state,
        checkout: action.checkout,
        syncing: false,
        errorCount: 0,
        bag: {
          ...state.bag,
          checkoutId: action.checkout?.id,
        },
      };
    case actionsTypes.UPDATE_CHECKOUT:
      return {
        ...state,
        checkout: action.checkout,
        bag: {
          ...state.bag,
          checkoutId: action.checkout?.id,
        },
      };
    case actionsTypes.SYNC_TRUE:
      return {
        ...state,
        syncing: true,
      };
    case actionsTypes.SYNC_FALSE:
      return {
        ...state,
        syncing: false,
      };
    case actionsTypes.SYNC_FAILED:
      return {
        ...state,
        syncing: false,
        errorCount: (state.errorCount += 1),
      };
    case actionsTypes.SYNC_RESET:
      return {
        ...state,
        syncing: false,
        errorCount: 0,
      };
    default:
      return state;
  }
}
